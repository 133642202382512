@use '../commons/colors';
.upload-files-container {
  border: 1px dashed #ccc;
  min-height: 200px;
  border-radius: 4px;
  padding: 24px;
  background-color: colors.$white;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.add-files {
    flex-direction: column;
    align-items: flex-start;
  }
  &.isDragging {
    background-color: colors.$grey7;
  }
  .upload-files-start {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 35px;
      line-height: 50px;
      font-weight: bold;
      margin: 2px 0 0 0;
    }
    span {
      font-size: 24px;
      color: colors.$grey1
    }
  }
  .upload-files-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    .file-item {
      display: grid;
      grid-template-columns: 2fr 1fr 24px;
      align-items: center;
      padding: 8px;
      border-bottom: 1px dashed #ccc;
      .file-item-name, .file-item-error {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .file-item-name {
        font-size: 16px;
        max-width: 80%;
      }
      .file-icon {
        height: 30px;
        min-width: 30px;
        display: block;
        background-size: 25px !important;
        margin-bottom: 4px;
      }
      .file-item-error {
        color: colors.$redDefault;
      }
      .file-item-warning {
        color: colors.$orangeDefault;
      }
      .file-item-success {
        color: colors.$greenDefault;
      }
      .file-item-info {
        display:flex;
        gap: 16px;
      }
    }
    .upload-progress {
      width: 60%;
    }
    .importing-progress {
      .progress {
        background-color: colors.$approvedGreen;
      }
      .progress-wrapper {
        background-color: rgba(colors.$approvedGreen, 0.24);
      }
    }
  }
  .upload-files-buttons {
    display: flex;
    margin-top: auto;
    margin-bottom: -6px;
    align-items: center;
    gap: 8px;
    padding-top: 16px;
    align-self: flex-start;
  }
}