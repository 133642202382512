@use "commons/colors";

body {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 100%;
}


.translate-box a.tooltip {
  text-align: center;
  text-decoration: none !important;
  position: relative;
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.translate-box a.tooltip.gray span,
.translate-box a.tooltip.gray {
  background: #eee;
}

.translate-box a.tooltip.gray span:after {
  border-top: 10px solid #eee;
}

.translate-box a.tooltip:hover span {
  top: -70px;
}
@media only screen and (max-width: 1320px) {
  .translate-box.tmx-select,
  .translate-box.source,
  .translate-box.target,
  .translate-box.project-subject {
    #project-subject,
    #source-lang,
    #target-lang {
      /*width: 150px ;*/
    }
    #tmx-select {
      /*width: 150px;*/
      &:hover {
        box-shadow: none !important;
      }
    }
    .menu-dropdown {
      max-height: none !important;
      overflow: hidden !important;
    }
    div.item {
      width: 100%;
      min-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px !important;
      span {
        line-height: 17px;
      }
      .no-descr {
        font-style: italic;
      }
    }
    .dropdown > span.text {
      margin-top: 0 !important;
      pointer-events: none;
      line-height: 28px !important;
      /*max-width: 90px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;*/

      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      white-space: nowrap;
    }
    .divider {
      margin: 0 !important;
    }
  }
}

@media only screen and (max-height: 600px) {
  .wrapper-claim {
    height: 58px !important;
  }
  .wrapper-claim h1 {
    font-size: 35px !important;
  }
  .upload-files,
  .gdrive-upload-file {
    margin: 6px 71px !important;
  }
}

@media (max-width: 480px) {
  .files .preview * {
    width: 40px;
  }
  .files .name * {
    display: inline-block;
    word-wrap: break-word;
  }
  .files .progress {
    width: 20px;
  }
  .files .delete {
    width: 60px;
  }
}
.translate-box {
  float: left;
  margin: 20px 0 0 0;
  position: relative;
  display: block;
}

.translate-box {
  h2 {
    line-height: 24px;
  }
}
.translate-box {
  h2,
  .select-with-label__wrapper label {
    color: colors.$grey1;
    font-size: 18px;
    font-weight: normal;
  }

  .select-with-label__wrapper {
    .select {
      font-size: 16px;
      padding: 9px 46px 9px 12px;
      border-radius: 2px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      box-shadow: inset 0 1px 3px #ddd;

      &:hover {
        border-color: rgba(34, 36, 38, 0.35);
        box-shadow: none;
      }
    }

    .select--is-focused,
    .select--is-focused:hover {
      border: solid 1px #96c8da;
      border-bottom: unset;
    }

    .select--is-disabled {
      background-color: #f3f3f3;
    }

    .custom-dropdown {
      margin-top: 0;
      padding-top: 12px;
      border-radius: unset;
      background-color: white;

      &::before {
        display: none;
      }

      .dropdown__search-bar {
        height: 34px;
        margin: 0 12px 12px;
        border-radius: 4px;
        border: 1px solid rgba(34, 36, 38, 0.15);
        background-color: white;

        .dropdown__search-bar-input {
          width: 100%;
          padding: 8px 8px;
          background-color: unset;
        }

        .dropdown__search-bar-input--highlighted {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .dropdown__option {
        padding: 8px;
      }

      .dropdown__option > span {
        color: black;
      }

      .dropdown__option--is-no-results-found {
        color: colors.$grey7;
      }

      .dropdown__option--is-no-results-found {
        background-color: unset;
      }

      .dropdown__option--is-active-option {
        background-color: unset;
        color: colors.$black;
        font-weight: bold;
        background-color: colors.$grey5;
      }

      .dropdown__option--is-highlighted-option,
      .dropdown__option:hover {
        background-color: colors.$grey4;
      }
    }

    .select__dropdown-wrapper {
      min-width: 350px;
      border: solid 1px #96c8da;
      border-top: unset;
      z-index: 3;
      margin-top: 1px;

      input:not([type='radio']),
      textarea {
        border: 1px solid colors.$grey2;
        border-radius: 4px;
        padding: 4px 8px;
        outline: none;
        font-size: 14px;
        line-height: 16px;
        &:focus,
        &:focus-visible {
          border-color: colors.$linkBlueTransparent;
        }
      }
      .dropdown__search-bar {
        box-shadow: none;
      }

      .button-top-of-list {
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        border: none;
        background-color: white;
        color: #39699a;
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        padding: 15px;
        border-top: 1px solid rgba(34, 36, 38, 0.1);
        border-bottom: 1px solid rgba(34, 36, 38, 0.1);

        span {
          font-size: 22px;
        }

        &:hover {
          background-color: colors.$grey4;
        }
      }
    }

    .select-with-icon__wrapper {
      z-index: 2;
      height: 36px;
      .select {
        color: black;
      }
    }
  }
}

.select-without-private-keys {
  .dropdown__option--is-no-results-found {
    display: none;
  }

  .no-private-keys-message {
    padding: 10px 15px;
    display: block;
  }
}

.translate-box.settings {
  display: flex;
  align-items: center;
  margin: 44px 0 0 0;
  cursor: pointer;
}
.translate-box.settings-disabled {
  opacity: 0.5;
}

.translate-box.qa-box {
  margin: 32px 0 0 !important;
}

.translate-box.qa-box h2 {
  display: inline;
}

.translate-box.qa-box img {
  float: right;
  width: 70px;
  margin-left: 5px;
  margin-top: -4px;
}

.translate-box input[type='checkbox'] {
  float: left;
  margin-top: 5px;
}

.translate-box.source {
  margin-right: 0px;
}

.wrapper-upload {
  text-align: left;
  margin: 0 auto;
  width: 94%;
  min-width: 992px;
  max-width: 1600px;
  position: relative;
  padding: 24px;
  background: colors.$grey5;
  padding-top: 0px;
  border-radius: 4px;
}

.wrapper-upload h1 {
  margin: 0;
  padding: 0;
  font-size: 40px;

  h2 {
    font-weight: 100;
  }
}

.wrapper-upload {
  .translate-box h2,
  .select-with-label__wrapper label {
    font-family: Calibri, Arial, Helvetica, sans-serif;
  }
}

.wrapper-claim {
  display: grid;
  align-items: center;
  justify-content: center;
  color: colors.$darkBlue;
  min-width: 992px;
  /*margin: -3px 0 10px 0;*/
  height: 175px;

  .wrapper-claim-content {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 16px;
  }

  svg {
    width: 50px;
  }
}

.wrapper-claim h1 {
  font-size: 40px;
  padding: 8px 0 6px;
  margin: 0px;
  font-family: Calibri, Arial, Helvetica, sans-serif;
  .highlight {
    color: colors.$translatedBlue;
  }
}

.wrapper-bottom {
  margin: 0 auto;
  width: 94%;
  min-width: 992px;
  position: relative;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  max-width: 1600px;
  align-items: center;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

/*button*/

.uploadbtn {
  font-weight: 500 !important;
  padding: 8px 16px;
  width: auto !important;
  min-width: 200px;
  font-size: 26px !important;
  &.disabled {
    background: colors.$grey2 !important;
  }
  .uploadloader {
    background: url(/public/img/loader.gif) center center no-repeat;
    width: 20px;
    height: 20px;
    float: right;
    margin-top: 3px;
    position: absolute;
    right: 233px;
    background-size: 20px 20px;
  }
}

.uploadbtn-box {
  display: flex;
  align-items: center;
}

.uploadbtn-box p {
  text-align: center;
  margin: -2px 0 0 5px;
  font-size: 13px;
  font-weight: normal;
  color: #999;
  display: none;
  text-align: center;
}

.uploadbtn-box:hover p {
  display: block;
}

a {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.translate-box a {
  display: block;
  font-size: 16px;
}

.translate-box label {
  margin-left: 5px;
}

body {
  font-family: Calibri, Arial, Helvetica, sans-serif;
}

.translate-box select {
  width: 257px;
  height: 37px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  margin: 0 0 5px 0;
  font-family: Calibri, Arial, Helvetica, sans-serif;
  border-radius: 2px;
}

a {
  color: colors.$linkBlue;
  &:hover {
    color: colors.$linkBlueHover;
  }
}

.supported-files {
  float: left;
  font-weight: bold;
  font-size: 16px;
  color: colors.$grey1;
  margin: 0px;
  .supported-file-formats {
    cursor: pointer;
  }
}

.btn {
  height: 44px;
  display: block;
  border: 1px solid #848689;
  text-decoration: none;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.disabled,
.disabled:hover,
.disabled:active {
  cursor: default;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  background: colors.$grey2;
  box-shadow: none !important;
}

.wrapper {
  width: 98%;
  min-width: 960px;
  margin: 0px auto 0 auto;
  padding: 5px 0 0 0;
  width: 92%;
  font-size: 14px;
  position: relative;
  text-align: left;
}

.popup-tm .popup {
  width: 94% !important;
  margin: 0 !important;
  left: 3% !important;
  top: 5%;
}

.popup-tm h1 {
  height: 50px;
}

.popup h3 {
  font-size: 16px;
  margin: 10px 0 5px 0;
  color: #333;
}

.popup .header {
  width: 96.2%;
  height: 20px;
  float: left;
  text-align: left;
  background: #efefef;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  -webkit-box-shadow: inset 0 1px 1px 1px #f4f7f9;
  box-shadow: inset 0 1px 1px 1px #f4f7f9;
  color: #333;
  font-size: 22px;
  font-weight: bold;
  padding: 5px 2.5% 10px 1.3%;
}

.popup ul {
  margin: 0px;
  padding: 0;
  float: left;
  list-style: none;
  font-size: 14px;
}

.popup ul li {
  margin: 3px 0px 3px 2px;
  padding: 1px 5px;
}

.close {
  background: url(/public/img/x.png) center 1px no-repeat;
  width: 22px;
  height: 20px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.close:hover,
.close:focus {
  background: url(/public/img/x.png) center -30px no-repeat;
}

.popup .header input {
  float: right;
  margin: 1px 0px 0px 15px !important;
  background: colors.$translatedBlue;
  font-weight: bold;
  float: right;
  font-size: 14px;
  cursor: pointer;
  color: #333;
  border: 1px solid #ccc !important;
  border-radius: 2px;
  border-radius: 2px;
  padding: 3px 12px !important;
  min-width: 150px;
  background: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    from(rgb(245, 245, 245)),
    to(rgb(211, 212, 213))
  );
  background: -moz-linear-gradient(top, colors.$translatedBlue, #119ec4);
  background: linear-gradient(top, colors.$translatedBlue, #119ec4);
}

.translate-box h2 span.extra {
  margin-left: 10px;
}

.supported-formats {
  height: 100%;
  .fileformat {
    display: flex;
    flex-direction: row;
    height: calc(100% - 50px);
    padding: 12px;
    gap: 8px;
    justify-content: space-between;
    overflow: auto;
    h3 {
      padding: 0 30px;
      background-color: #efefef;
      text-align: center;
    }

    span {
      padding: 10px 0px 10px 40px;
      margin: 5px 0 0 0;
      width: 100%;
      height: 35px;
      float: left;
      background-size: 25px !important;
    }
    .format-box {
      float: none;
      flex: 1 1 auto;
      max-width: 300px;
      .file-list {
        float: left;
        margin: 0 auto;
        float: none;
        overflow: hidden;
        > div {
          width: 135px;
          margin: 0;
          padding: 0 5px;
          float: left;
          text-align: left;
        }
      }
    }
  }
}

#delete-failed-conversions {
  display: none;
}

ul.test {
  column-count: 4;
}

ul.test li {
  float: left;
  width: 200px;
  background: yellow;
}

#swaplang {
  float: left;
  display: block;
  text-decoration: none;
  height: 18px;
  margin: 52px 14px 0 5px;
  font-size: 20px;
  color: #ccc;
}

#swaplang span {
  display: none;
}

#swaplang:hover {
  color: #ddd;
}

.name {
  padding: 8px 0px 5px 20px !important;
}

/* file extensions */

.preview span {
  height: 30px;
  width: 30px;
  display: block;
  background-size: 25px !important;
}

.error .label.label-important a {
  cursor: pointer;
}

.modal-gdrive {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999999;
  background: rgba(255, 255, 255, 0.5);
}

header .nav-bar .dropdown.select-org span.text {
  line-height: 32px;
}

.ui.user.label {
  top: 0px !important;
}

.translate-box input:focus {
  border-color: #85b7d9;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.8);
  outline: none;
}

select:focus {
  border-color: #85b7d9;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.8);
  outline: none;
}

.ui.selection.dropdown {
  box-shadow: inset 0 1px 3px #ddd;
}

.ui.input input {
  box-shadow: inset 0 1px 3px #ddd;
}
/* Header restyling */

.action-submenu {
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

.not-supported-container {
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  color: #002b5a;
  div.buttons {
    padding-top: 20px;
  }
}

.project-template-select {
  .select-item-default {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    color: colors.$black;

    .select-item-default-label {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 160px;
    }

    & > svg {
      opacity: 1;
    }
  }

  .dropdown__option--is-active-option {
    .select-item-default {
      margin-right: 4px;
      color: colors.$white;
    }
  }
}

.translate-box {
  .project-template-select {
    .dropdown__option--is-active-option {
      .select-item-default {
        color: colors.$black;
      }
    }
  }
}

.project-template-select-unsaved {
  .select {
    color: colors.$translatedBlue !important;
  }
}

.project-template-select-loading {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 36px;
  top: 24px;
  background-color: rgba(255, 255, 255, 0.9);

  .project-template-select-loading-icon {
    background: url(/public/img/loading.gif) 47% 6px no-repeat !important;
    background-size: 24px !important;
    height: 100%;
  }
}

.project-template-select {
  .select-item-default {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    color: colors.$black;

    .select-item-default-label {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 160px;
    }

    & > svg {
      opacity: 1;
    }
  }

  .dropdown__option--is-active-option {
    .select-item-default {
      margin-right: 4px;
      color: colors.$white;
    }
  }
}

.translate-box {
  .project-template-select {
    .dropdown__option--is-active-option {
      .select-item-default {
        color: colors.$black;
      }
    }
  }
}

.project-template-select-unsaved {
  .select {
    color: colors.$translatedBlue !important;
  }
}

.project-template-select-loading {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 36px;
  top: 24px;
  background-color: rgba(255, 255, 255, 0.9);

  .project-template-select-loading-icon {
    background: url(/public/img/loading.gif) 47% 6px no-repeat !important;
    background-size: 24px !important;
    height: 100%;
  }
}

/* XLIFF TO TARGET */
// wrapper container page xliff to target
.xliff_to_target__page {
  .wrapper-upload {
    padding-top: 24px;
  }
  .upload-files-container {
    margin-top: 0;
    &.add-files {
      align-items: center;
      gap: 40px;
    }
  }
  footer {
    position: absolute;
    bottom: 0;
  }

}

.error-message,
.warning-message {
  background: #d65757;
  color: #fff;
  padding: 10px 0 10px 16px;
  margin: 10px 0;
  font-weight: bold;
  width: 100%;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #c45f5f;
  display: flex;
  .icon {
    float: left;
    font-size: 25px;
  }
  p {
    line-height: 25px;
    font-size: 16px;
    float: left;
    margin-left: 12px;
  }
}
.error-message {
  a {
    color: #fff;
  }
}

.warning-message {
  background: rgba(255, 250, 139, 0.38) !important;
  border-color: #6d6e71;
  color: #000;
}