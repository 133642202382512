@use '../../commons/colors';

.progress-bar-container {
  display: flex;
  align-items: center;
  gap: 8px;

  &.withLabel {
    flex-direction: column;
    justify-content: center;
    gap: 4px;
  }
  .progress-wrapper {
    position: relative;
    border-radius: 6px;
    width: 100%;
    height: 4px;

    &.medium {
      height: 6px;
    }
    &.big  {
      height: 8px;
    }
  }
  .progress {
    border-radius: 6px;
    height: 4px;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);

    &.medium {
      height: 6px;
    }
    &.big {
      height: 8px;
    }
  }

  .labelProgress {
    width: 32px;
    text-align: right;
  }
  .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    color: colors.$grey6;
    .labelProgress {
      width: auto;
    }
  }

  // Type modifiers
  &.default {
    .progress-wrapper {
      background-color: rgba(colors.$translatedBlue, 0.24);
    }
    .progress {
      background-color: colors.$translatedBlue;
    }
    .labelProgress span {
      color: colors.$translatedBlue;
    }

    &.complete {
      .progress {
        background-color: colors.$approvedGreen;
      }
      .labelProgress span {
        color: colors.$approvedGreen;
      }
    }
  }
}