@use '../../commons/colors';

.other-options-box {
  float: left;
  width: 100%;
  padding: 20px;

  .options-box:last-child {
    border-bottom: 0;
  }

  > h2 {
    font-size: 30px;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 0px;
  }

  .options-box h3 {
    font-size: 19px;
    margin-bottom: 0;
  }

  .options-box .option-description {
    width: 65%;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    line-height: 22px;
    align-items: start;
    text-align: left;
  }

  .options-box.option-unavailable p,
  .options-box.option-unavailable h3 {
    opacity: 0.6;
  }

  .options-box.qa-box img {
    width: 65px !important;
    margin-right: 10px !important;
    vertical-align: middle;
    margin-left: 5px;
  }

  //**** SELECT ****//
  .select-with-label__wrapper {
    width: 300px;

    .select {
      font-size: 16px;
      padding: 9px 46px 9px 12px;
      border-radius: 2px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      box-shadow: inset 0 1px 3px #ddd;

      &:hover {
        border-color: rgba(34, 36, 38, 0.35);
        box-shadow: none;
      }
    }

    .select--is-focused,
    .select--is-focused:hover {
      border: solid 1px #96c8da;
    }

    .select--is-disabled {
      background-color: #f3f3f3;
    }

    .custom-dropdown {
      margin-top: 0;
      padding-top: 12px;
      border-radius: unset;
      background-color: white;

      &::before {
        display: none;
      }

      .dropdown__search-bar {
        height: 34px;
        margin: 0 12px 12px;
        border-radius: 4px;
        border: 1px solid rgba(34, 36, 38, 0.15);
        background-color: white;

        .dropdown__search-bar-input {
          width: 100%;
          padding: 8px 8px;
          background-color: unset;
        }

        .dropdown__search-bar-input--highlighted {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .dropdown__option {
        padding: 8px;
      }

      .dropdown__option > span {
        color: black;
      }

      .dropdown__option--is-no-results-found {
        color: colors.$grey7;
      }

      .dropdown__option--is-no-results-found {
        background-color: unset;
      }

      .dropdown__option--is-active-option {
        background-color: unset;
        font-weight: bold;
        background-color: colors.$grey5;
      }

      .dropdown__option--is-highlighted-option,
      .dropdown__option:hover {
        background-color: colors.$grey4;
      }
    }

    .select__dropdown-wrapper {
      min-width: 280px;
      z-index: 1;
      margin-top: 1px;

      input:not([type='radio']),
      textarea {
        border: 1px solid colors.$grey2;
        border-radius: 4px;
        padding: 4px 8px;
        outline: none;
        font-size: 14px;
        line-height: 16px;
        &:focus,
        &:focus-visible {
          border-color: colors.$linkBlueTransparent;
        }
      }
      .dropdown__search-bar {
        box-shadow: none;
      }
    }

    .select-with-icon__wrapper {
      height: 36px;
      .select {
        color: black;
      }
    }

    .select__dropdown--is-reversed .custom-dropdown {
      margin-bottom: 0;
    }
  }

  //**** END SELECT ****//
}

.options-box {
  display: flex;
  gap: 40px;
  align-items: center;
  padding: 30px 0;

  &:first-child {
    padding: 0 0 15px;
  }
}

.tooltip-options {
  cursor: help;
  font-weight: bold;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  margin-left: 6px;
}

.options-select-container {
  gap: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.option-characters-counter-rule-dropdown {
  .dropdown__option span {
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }

  .custom-dropdown {
    padding-top: 0;
  }
}

.option-characters-counter-rule-select-option-content {
  display: flex;
  flex-direction: column;
  text-align: left;

  > p {
    font-weight: normal;
    color: colors.$grey6;
  }
}
