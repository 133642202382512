@use "../../commons/colors";

.tooltip-container {
  position: absolute;
  z-index: 12;
  border-radius: 4px;
  padding: 5px 10px;
  background-color: colors.$white;
  box-shadow: 0 0 16px rgba(colors.$black, 0.25);
  color: colors.$grey6;
  pointer-events: none;
  white-space: normal;
  text-align: left;
  max-width: 600px;
  word-break: break-word;
  animation: 0.1s linear forwards showing;

  &.top {
    transform: translate(-50%, -100%);
  }
  &.bottom {
    transform: translate(-50%, 0);
  }
  &.left {
    transform: translate(-100%, -50%);
  }
  &.right {
    transform: translate(0, -50%);
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 4;
    width: 0;
    height: 0;
    pointer-events: none;
    //filter: drop-shadow($floating-element-shadow);
  }
  &.top,
  &.bottom {
    &::before {
      left: 50%;
      margin-left: -4px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
  }
  &.top::before {
    bottom: -6px;
    border-top: 6px solid colors.$white;
  }
  &.bottom::before {
    top: -6px;
    border-bottom: 6px solid colors.$white;
  }
  &.left,
  &.right {
    &::before {
      top: 50%;
      margin-top: -4px;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }
  &.left::before {
    right: -6px;
    border-left: 6px solid colors.$white;
  }
  &.right::before {
    left: -6px;
    border-right: 6px solid colors.$white;
  }
}

.tooltip-container-interactive {
  pointer-events: auto;
}

@keyframes showing {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
